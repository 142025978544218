import styled from 'styled-components';
import { FluidType } from './FluidType';

export default styled.h1`
  text-align: ${props => props.align || 'left'};
  color: ${props => props.color || '#484848'};
  font-family: 'Futura Std Heavy', sans-serif;
  margin: ${props => props.margin || 0};
  font-style: ${props => props.fontStyle || 'normal'};
  font-weight: ${props => props.fontWeight || 900};
  ${FluidType('10px', '48px', '320px', '1200px')}
`;
