import React, { useState } from "react";
import { withRouter } from "react-router";
import styled from "styled-components";
import { connect } from "react-redux";

import { Avatar, Button, Col, Drawer, Row, Typography } from "antd";

/** App theme */
import { Colors, Images, Metrics } from "../../themes";
import HamburgerIcon from "../PublicSidebar/HamburgerIcon";
import SideBarLink from "../PublicSidebar/SideBarLink";
import { LogoCont } from "../PublicSidebar/StyledElements";
import { ReadButton } from "../Styled";
import PrivateHeader from "./privateHeader";

const { green, textGray, white, purple } = Colors;
const { Text } = Typography;
const {
  padding: { responsive }
} = Metrics;

const LoginButton = styled(Button)`
  color: ${green} !important;
  border-color: ${green} !important;
  margin-left: ${responsive};
`;
const HeaderWrapper = styled.header`
  align-items: center;
  background-color: ${white};
  color: ${white};
  display: flex;
  height: 8vh;
  max-height: 84px;
  justify-content: space-between;
  padding: 1%;
  box-shadow: 1px 0px 5px 1px rgba(0, 0, 0, 0.15);
`;
const LogoWrapper = styled.img`
  max-height: 60px;
  height: 8vh;
  pointer-events: none;
  width: 100%;
`;
const Authdiv = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 30vw;
  margin-top: ${Metrics.marginTop};
  padding: 0 5%;
`;
const Header = props => {
  const { logo } = Images;
  const {
    loggedIn,
    history,
    role,
    user,
    navItems,
    color,
    noSignup,
    noLogin
  } = props;
  const [showDrawer, setShowDrawer] = useState(false);
  const showSideLink = () => {
    if (role === "admin")
      return (
        <SideBarLink
          to="/admin/dashboard"
          key={"dashboard"}
          title={"Go to Dashboard"}
          color={purple}
          open={showDrawer}
        />
      );
    if (role === "employer")
      return (
        <SideBarLink
          to="/jobs"
          key={"jobs"}
          title={"Go to Dashboard"}
          color={purple}
          open={showDrawer}
        />
      );
    if (role === "advertiser")
      return (
        <SideBarLink
          to="/adverts"
          key={"adverts"}
          title={"Go to Dashboard"}
          color={purple}
          open={showDrawer}
        />
      );
    if (role === "blogger")
      return (
        <SideBarLink
          to="/blogs"
          key={"blogs"}
          title={"Go to Dashboard"}
          color={purple}
          open={showDrawer}
        />
      );
    /*
    if (role === "user")
      return (
        <SideBarLink
          to="/dashboard"
          key={"dashboard"}
          title={"Go to Dashboard"}
          color={purple}
          open={showDrawer}
        />
      );*/
  };
  const nextLink = () => {
    if (role === "admin") return "/admin/dashboard";
    if (role === "employer") return "/jobs";
    if (role === "advertiser") return "/adverts";
    if (role === "blogger") return "/blogs";
    return "/dashboard";
  };
  if (
    loggedIn &&
    history.location.pathname !== "/" &&
    history.location.pathname !== "/details"
  ) {
    return <PrivateHeader role={role} history={history} user={user} />;
  } else {
    return (
      <HeaderWrapper>
        <LogoCont>
          <HamburgerIcon onClick={() => setShowDrawer(true)} />
          <span onClick={() => history.push("/")}>
            <LogoWrapper src={logo} alt="logo" />
          </span>
        </LogoCont>
        <Drawer
          title={
            <span onClick={() => history.push("/")}>
              <LogoWrapper src={logo} alt="logo" />
            </span>
          }
          placement="left"
          closable={false}
          onClose={() => setShowDrawer(false)}
          visible={showDrawer}
        >
          {navItems &&
            navItems.length > 0 &&
            navItems.map(i => (
              <SideBarLink
                to={i.to}
                key={i.title}
                icon={i.icon}
                title={i.title}
                subMenu={i.subMenu}
                color={color || textGray}
                open={showDrawer}
              />
            ))}
          {!loggedIn && (
            <SideBarLink
              to={"/signup"}
              key={"Signup"}
              title={"SIGN UP"}
              color={purple}
              open={showDrawer}
            />
          )}
          {!loggedIn && (
            <SideBarLink
              to={"/login"}
              key={"login"}
              title={"LOG IN"}
              color={purple}
              open={showDrawer}
            />
          )}
          {loggedIn && showSideLink()}
        </Drawer>
        {loggedIn ? (
          <Row type="flex" justify="end" gutter={16} style={{ width: "65%" }}>
            <Col xs={0} sm={0} md={12} lg={6} style={{ textAlign: "right" }}>
              {(history.location.pathname === "/" ||
                history.location.pathname === "/details") &&
                role !== "admin" && role !== "user" && (
                  <Button
                    type="primary"
                    ghost
                    onClick={() => history.push(nextLink())}
                  >
                    Go to Dashboard
                  </Button>
                )}
              {(history.location.pathname === "/" ||
                history.location.pathname === "/details") &&
                role === "admin"  && (
                  <Button
                    type="primary"
                    ghost
                    onClick={() => history.push(nextLink())}
                  >
                    Go to Dashboard
                  </Button>
                )}
            </Col>
            {/* {role === 'employer' && (
							<Col xs={0} sm={0} md={8} lg={6} style={{ textAlign: 'right' }}>
								<Button type="primary" icon="plus-circle" ghost onClick={() => history.push('/jobs/new')}>
									Create a Job
								</Button>
							</Col>
						)} */}
            {role === "advertiser" && (
              <Col xs={0} sm={0} md={8} lg={6} style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  icon="plus-circle"
                  ghost
                  onClick={() => history.push("/adverts/new")}
                >
                  Create an Advert
                </Button>
              </Col>
            )}
            {role === "blogger" && (
              <Col xs={0} sm={0} md={8} lg={6} style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  icon="plus-circle"
                  ghost
                  onClick={() => history.push("/blogs/new")}
                >
                  Write an article
                </Button>
              </Col>
            )}
            <Col xs={0} sm={0} md={8} lg={8} onClick={() => history.push("/settings")}>
              <Text>
                <Avatar
                  src={user.profileUrl || Images.profileAvatar}
                  size="large"
                  style={{ backgroundColor: white, color: textGray }}
                />{" "}
                Welcome, {user.name}
              </Text>
            </Col>
          </Row>
        ) : (
          <Col xs={0} sm={0} md={15} lg={12}>
            <Authdiv>
              <ReadButton onClick={() => history.push("/articles")}>
                &nbsp;&nbsp;&nbsp;Read Articles
              </ReadButton>
              {!noSignup && (
                <Button
                  type="secondary"
                  onClick={() => history.push("/signup")}
                >
                  <strong>SIGN UP</strong>
                </Button>
              )}
              {!noLogin && (
                <LoginButton ghost onClick={() => history.push("/login")}>
                  <strong>LOGIN</strong>
                </LoginButton>
              )}
            </Authdiv>
          </Col>
        )}
      </HeaderWrapper>
    );
  }
};

const mapStateToProps = state => ({
  loggedIn: !!state.user.currentUser,
  user: state.user.currentUser,
  role: state.user.currentUser && state.user.currentUser.role
});

export default withRouter(connect(mapStateToProps)(Header));
