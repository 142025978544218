import React from 'react';
import { withRouter } from 'react-router-dom';

import { Icon } from 'antd';
import SidebarContainer from '../../components/Sidebar';
import { Colors } from '../../themes';

const { iconGray, textGray } = Colors

export const NAV_ITEMS = [
 /* {
    to: '/dashboard',
    title: 'Dashboard',
    icon: (
      <Icon
        type="dashboard"
        style={{ color: iconGray }}
        className="sidebar-menu-icon"
      />
    ),
    role: 'user'
  },*/
  {
    to: '/jobs',
    title: 'Jobs',
    icon: (
      <Icon
        type="project"
        style={{ color: iconGray }}
        className="sidebar-menu-icon"
      />
    ),
    role: 'employer'
  },
  {
    to: '/adverts',
    title: 'Adverts',
    icon: (
      <Icon
        type="picture"
        style={{ color: iconGray }}
        className="sidebar-menu-icon"
      />
    ),
    role: 'advertiser'
  },
  {
    to: '/blogs',
    title: 'Blogs',
    icon: (
      <Icon
        type="profile"
        style={{ color: iconGray }}
        className="sidebar-menu-icon"
      />
    ),
    role: 'blogger'
  },
  {
    to: '/admin/dashboard',
    title: 'Dashboard',
    icon: (
      <Icon
        type="dashboard"
        style={{ color: iconGray }}
        className="sidebar-menu-icon"
      />
    ),
    role: 'admin'
  },
  {
    to: '/admin/jobs',
    title: 'Jobs',
    icon: (
      <Icon
        type="project"
        style={{ color: iconGray }}
        className="sidebar-menu-icon"
      />
    ),
    role: 'admin'
  },
  {
    to: '/admin/adverts',
    title: 'Adverts',
    icon: (
      <Icon
        type="picture"
        style={{ color: iconGray }}
        className="sidebar-menu-icon"
      />
    ),
    role: 'admin'
  },
  {
    to: '/admin/blogs',
    title: 'Blogs',
    icon: (
      <Icon
        type="profile"
        style={{ color: iconGray }}
        className="sidebar-menu-icon"
      />
    ),
    role: 'admin'
  },
  {
    to: '/admin/users',
    title: 'Users',
    icon: (
      <Icon
        type="team"
        style={{ color: iconGray }}
        className="sidebar-menu-icon"
      />
    ),
    role: 'admin'
  },
  {
    to: '/settings',
    title: 'Settings',
    icon: (
      <Icon
        type="setting"
        style={{ color: iconGray }}
        className="sidebar-menu-icon"
      />
    ),
    role: 'all'
  },
  {
    to: '/logout',
    title: 'Logout',
    icon: (
      <Icon
        type="logout"
        style={{ color: iconGray }}
        className="sidebar-menu-icon"
      />
    ),
    role: 'all'
  },
];
const Sidebar = props => {
  const { children } = props;
  return (
    <React.Fragment>
      <SidebarContainer
        navItems={NAV_ITEMS}
        color={textGray}
        children={children}
        {...props}
      />
    </React.Fragment>
  );
};

export default withRouter(Sidebar);
