import { convertPixelsToRem } from '../utils/helpers';

// Used via Metrics.baseMargin
const metrics = {
	marginHorizontal: convertPixelsToRem(10),
	marginVertical: convertPixelsToRem(10),
	marginTop: convertPixelsToRem(8),
	baseMargin: '2%',
	doubleBaseMargin: convertPixelsToRem(20),
	smallMargin: convertPixelsToRem(5),
	navBarHeight: convertPixelsToRem(50),
	tabBarHeight: convertPixelsToRem(50),
	buttonRadius: convertPixelsToRem(4),
	padding: {
		baseVertical: convertPixelsToRem(6),
		baseHorizontal: convertPixelsToRem(12),
		lgVertical: convertPixelsToRem(10),
		lgHorizontal: convertPixelsToRem(16),
		smVertical: convertPixelsToRem(5),
		smHorizontal: convertPixelsToRem(10),
		xsVertical: convertPixelsToRem(1),
		xsHorizontal: convertPixelsToRem(5),
		responsive: '2%',
		largeResponsive: '5%',
	},
	icons: {
		tiny: convertPixelsToRem(15),
		small: convertPixelsToRem(20),
		medium: convertPixelsToRem(30),
		large: convertPixelsToRem(45),
		xl: convertPixelsToRem(60),
	},
	images: {
		small: convertPixelsToRem(20),
		medium: convertPixelsToRem(40),
		large: convertPixelsToRem(60),
		logo: convertPixelsToRem(300),
	},
	borderRadius: {
		base: convertPixelsToRem(4),
		large: convertPixelsToRem(8),
		small: convertPixelsToRem(3),
	},
	// -- Z-index master list
	//
	// Warning: Avoid customizing these values. They're used for a birds' eye view
	// of components dependent on the z-axis and are designed to all work together.
	zIndex: {
		navbar: 1000,
		dropdown: 1000,
		popover: 1060,
		tooltip: 1070,
		navbarFixed: 1030,
		modalBackground: 1040,
		modal: 1050,
		responsiveMenus: 1010,
	},
};

export default metrics;
