import styled from 'styled-components';
import { FluidType } from './FluidType';

export default styled.p`
  text-align: ${props => props.align || 'left'};
  color: ${props => props.color};
  font-family: 'PT Sans', sans-serif;
  font-weight: ${props => props.fontWeight || '300'};
  margin: ${props => props.margin || 0};
  ${FluidType('11px', '13px', '320px', '1200px')};
  &:hover {
    cursor: ${props => props.hover && 'pointer'};
  }
`;
