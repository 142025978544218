import React from "react";
import styled from "styled-components";

import { Avatar, Button, Col, Row, Typography } from "antd";

/** App theme */
import { Colors, Images, Metrics } from "../../themes";
import { convertPixelsToRem } from "../../utils/helpers";

const { Text } = Typography;
const { textGray, white } = Colors;
const { profileAvatar } = Images;

export const HeaderWrapper = styled.header`
  align-items: center;
  background-color: ${white};
  color: ${white};
  display: flex;
  height: 8vh;
  max-height: 84px;
  justify-content: space-between;
  padding: 1%;
  box-shadow: 1px 0px 5px 1px rgba(0, 0, 0, 0.15);
`;
export const LogoWrapper = styled.img`
  max-height: 60px;
  height: 6vh;
  pointer-events: none;
`;
export const ProfileDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 30vw;
  margin-top: ${Metrics.marginTop};
  align-items: center;
`;

const privateHeader = props => {
  const { shortLogo, logo } = Images;
  const { role, history, user } = props;
  return (
    <HeaderWrapper>
      {/* <ProfileDiv> */}
      <Row
        type="flex"
        justify="space-between"
        gutter={16}
        style={{ width: "100%" }}
      >
        <Col xs={8} sm={8} md={8} lg={12} style={{ textAlign: "left" }}>
          >
          {history.location.pathname === "/" ||
          history.location.pathname === "/details" ? (
            <LogoWrapper
              src={logo}
              alt="logo"
              onClick={() => history.push("/")}
            />
          ) : (
            <span onClick={() => history.push("/")}>
              <LogoWrapper src={shortLogo} alt="logo" />
            </span>
          )}
        </Col>
        {/* {role === 'employer' &&
            <Col xs={24} sm={12} md={12} lg={6} style={{ textAlign: "right" }}>
                <Button type="primary" icon="plus-circle" ghost onClick={() => history.push("/jobs/new")}>
                  Create a Job
                </Button>
            </Col>} */}
        {role === "advertiser" && (
          <React.Fragment>
            <Col
              xs={0}
              sm={0}
              md={8}
              lg={6}
              style={{ textAlign: "right", margin: "auto 0" }}
            >
              <Button
                type="primary"
                icon="plus-circle"
                ghost
                onClick={() => history.push("/adverts/new")}
              >
                Create an Advert
              </Button>
            </Col>
            <Col
              xs={8}
              sm={8}
              md={0}
              lg={0}
              style={{
                textAlign: "right",
                margin: "auto 0"
              }}
            >
              <Button
                type="primary"
                icon="plus-circle"
                ghost
                onClick={() => history.push("/adverts/new")}
              >
                Advert
              </Button>
            </Col>
          </React.Fragment>
        )}
        {role === "blogger" && (
          <React.Fragment>
            <Col
              xs={0}
              sm={0}
              md={8}
              lg={6}
              style={{
                textAlign: "right",
                margin: "auto 0"
              }}
            >
              <Button
                type="primary"
                icon="plus-circle"
                ghost
                onClick={() => history.push("/blogs/new")}
              >
                Write an article
              </Button>
            </Col>
            <Col
              xs={8}
              sm={8}
              md={0}
              lg={0}
              style={{
                textAlign: "right",
                margin: "auto 0"
              }}
            >
              <Button
                type="primary"
                icon="plus-circle"
                ghost
                onClick={() => history.push("/blogs/new")}
              >
                Write
              </Button>
            </Col>
          </React.Fragment>
        )}
        <Col
          xs={0}
          sm={0}
          md={8}
          lg={6}
          onClick={() => history.push("/settings")}
          style={{
            textAlign: "center",
            justifyContent: "flex-end"
          }}
        >
          <Avatar
            src={user.profileUrl || profileAvatar}
            size="large"
            style={{ backgroundColor: white, color: textGray, margin: "2% 0" }}
          />{" "}
          <Text style={{ margin: `auto ${convertPixelsToRem(5)}` }}>
            {user.name}
          </Text>
        </Col>
        <Col
          xs={8}
          sm={8}
          md={0}
          lg={0}
          onClick={() => history.push("/settings")}
          style={{ textAlign: "right" }}
        >
          <Text>
            <Avatar
              src={user.profileUrl || profileAvatar}
              size="large"
              style={{ backgroundColor: white, color: textGray }}
            />{" "}
          </Text>
        </Col>
      </Row>
      {/* </ProfileDiv> */}
    </HeaderWrapper>
  );
};

export default privateHeader;
