import styled from 'styled-components';
import { color } from 'styled-system';

import { Colors } from '../../themes';

export const PageWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
`;

export const SideBarWrapper = styled.div`
  ${color};
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  position: fixed;
  max-width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  width: ${props => (props.open ? '200px' : '60px')};
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  background: ${Colors.white};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 1px 0px 5px 1px rgba(0, 0, 0, 0.15);
  & .sidebar-menu-icon {
    font-size: 21px;
    margin-left: 20px;
    color: ${props => (props.active && `${Colors.white} !important`)};
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  & .nav-link-text-hidden > div > span {
    opacity: 0;
    visibility: hidden;
    width: 0;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
`;

export const Logo = styled.img`
  max-width: 100%;
  height: 6vh;
  max-height: 60px;
  display: ${props => (props.open ? 'inline-block' : 'none')};
  margin: ${props => (props.open ? '0 20px 0 0' : '0 -15px 0 0')};
`;

export const LogoCont = styled.div`
  height: 8vh;
  z-index: 200;
  display: flex;
  position: fixed;
  position: relative;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid;
  transition-property: width;
  justify-content: space-between;
  width: ${props => (props.open ? '200px' : '60px')};
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  & ${Logo} {
    transition-property: visibility, opacity;
    opacity: ${props => (props.open ? 1 : 0)};
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    visibility: ${props => (props.open ? 'visible' : 'hidden')};
  }
`;

export const LogoutButton = styled.div`
  left: 0;
  bottom: 0;
  height: 50px;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: flex-start;
  background-color: 'transparent';
  border-top: 1px solid rgba('255, 255, 255, 0.6');
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  width: ${(props) => (props.open ? '250px' : '60px')};
  &:hover {
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    background-color: ${Colors.hover};
  }
`;
