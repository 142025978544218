import Loadable from "react-loadable";
import Loading from "./Loading";

const AsyncJobs = Loadable({
  loader: () => import("../containers/Jobs"),
  loading: Loading
});

const AsyncNewJob = Loadable({
  loader: () => import("../containers/Jobs/NewJob"),
  loading: Loading
});

const AsyncEditJob = Loadable({
  loader: () => import("../containers/Jobs/EditJob"),
  loading: Loading
});

const AsyncAdvertisement = Loadable({
  loader: () => import("../containers/Adverts"),
  loading: Loading
});
const AsyncNewAdvertisement = Loadable({
  loader: () => import("../containers/Adverts/NewAdverts"),
  loading: Loading
});
const AsyncEditAdvertisement = Loadable({
  loader: () => import("../containers/Adverts/EditAdverts"),
  loading: Loading
});

const AsyncBlog = Loadable({
  loader: () => import("../containers/Blogs"),
  loading: Loading
});

const AsyncNewBlog = Loadable({
  loader: () => import("../containers/Blogs/NewBlog"),
  loading: Loading
});

const AsyncEditBlog = Loadable({
  loader: () => import("../containers/Blogs/EditBlog"),
  loading: Loading
});

const AsyncProfile = Loadable({
  loader: () => import("../containers/Profile"),
  loading: Loading
});
const AsyncEditProfile = Loadable({
  loader: () => import("../containers/Profile/editProfile"),
  loading: Loading
});
const AsyncResetPassword = Loadable({
  loader: () => import("../containers/Profile/changePassword"),
  loading: Loading
});

const AsyncLogout = Loadable({
  loader: () => import("../containers/Login/Logout"),
  loading: Loading
});

let Routes = [
  {
    title: "Jobs",
    path: "/jobs",
    component: AsyncJobs,
    exact: true,
    role: "employer"
  },
  {
    title: "New Job",
    path: "/jobs/new",
    component: AsyncNewJob,
    exact: true,
    role: "employer"
  },
  {
    title: "Edit Job",
    path: "/jobs/edit/:id",
    component: AsyncEditJob,
    exact: true
  },
  // {
  //   title: 'Browse Jobs',
  //   path: '/browse/:type',
  //   component: AsyncBrowseJobs,
  //   exact: true
  // },
  // {
  //   title: 'View Job',
  //   path: '/detail/:jobId',
  //   component: AsyncJobDetail,
  //   exact: true
  // },
  {
    title: "Advertisement",
    path: "/adverts",
    component: AsyncAdvertisement,
    exact: true,
    role: "advertiser"
  },
  {
    title: "Advertisement",
    path: "/adverts/new",
    component: AsyncNewAdvertisement,
    exact: true
  },
  {
    title: "Advertisement",
    path: "/adverts/edit/",
    component: AsyncEditAdvertisement,
    exact: true
  },
  {
    title: "Blog",
    path: "/blogs",
    component: AsyncBlog,
    exact: true,
    role: "blogger"
  },
  {
    title: "Add Blog",
    path: "/blogs/new",
    component: AsyncNewBlog,
    exact: true
  },
  {
    title: "Edit Blog",
    path: "/blogs/edit/:blogId",
    component: AsyncEditBlog,
    exact: true
  },
  {
    title: "Profile",
    path: "/settings",
    component: AsyncProfile,
    exact: true
  },
  {
    title: "Edit Profile",
    path: "/settings/edit",
    component: AsyncEditProfile,
    exact: true
  },
  {
    title: "Reset Password",
    path: "/settings/changePassword",
    component: AsyncResetPassword,
    exact: true
  },
  {
    title: "Logout",
    path: "/logout",
    component: AsyncLogout,
    exact: true,
    role: "all"
  }
];

export default Routes;
