/**Images */
import about from "../assets/Images/about.jpg";
import aboutW from "../assets/Images/about.webp";
import author from "../assets/Images/author.jpg";
import authorW from "../assets/Images/author.webp";
import authorbg1 from "../assets/Images/authorbg1.svg";
import advertiserbg1 from "../assets/Images/advertiserbg1.jpg";
import advertiserbg3 from "../assets/Images/advertiserbg3.jpg";
import articlesButton from "../assets/Images/articlesButton.svg";
import backgroundImg from "../assets/Images/background.jpg";
import blog from "../assets/Images/blog.jpg";
import cactus from "../assets/Images/cactus.svg";
import comms from "../assets/Images/comms.jpg";
import commsW from "../assets/Images/comms.webp";
import employerBg1 from "../assets/Images/employerBg1.svg";
import error from "../assets/Images/error.svg";
import heartbeat from "../assets/Images/heartbeat.png";
import hospital from "../assets/Images/hospital.jpg";
import hospitalW from "../assets/Images/hospital.webp";
import jobAlert from "../assets/Images/jobAlert.jpg";
import jobSeekerImg from "../assets/Images/jobSeeker.jpg";
import jobSeekerImgW from "../assets/Images/jobSeeker.webp";
import jobSeekerbg1 from "../assets/Images/jobSeekerbg.jpg";
import jobSeekerbg2 from "../assets/Images/jobSeekerbg2.jpg";
import jobSeekerbg3 from "../assets/Images/jobSeeker3.jpg";
import loginbg from "../assets/Images/loginbg.jpg";
import notFound from "../assets/Images/not_found.svg";
import orangeBg from "../assets/Images/orangeBg.jpg";
import profile from "../assets/Images/profile.jpg";
import purpleBg from "../assets/Images/purpleBg.jpg";
import read from "../assets/Images/readNow.svg";
import slider1 from "../assets/Images/slider1.jpg";
import sliderW1 from "../assets/Images/slider1.webp";
import signup from "../assets/Images/signupPhoto.jpg";
import underConstruction from "../assets/Images/under_construction.svg";
import whyPhoto from "../assets/Images/why.jpg";
import whyPhotoW from "../assets/Images/why.webp";

/** Icons */
import adminAdvertiser from "../assets/Icons/adminAdvertiser.svg";
import adminJob from "../assets/Icons/adminJob.svg";
import adminAuthor from "../assets/Icons/adminAuthor.svg";
import adminEmployer from "../assets/Icons/adminEmployer.svg";
import darkGreenLineGraph from "../assets/Icons/darkGreenLineGraph.svg";
import denied from "../assets/Icons/denied.svg";
import edit from "../assets/Icons/edit.svg";
import greenLineGraph from "../assets/Icons/greenLineGraph.svg";
import hourglass from "../assets/Icons/hourglass.svg";
import lightBulb from "../assets/Icons/lightBulb.svg";
import pause from "../assets/Icons/pause.svg";
import profileAvatar from "../assets/Icons/user.svg";
import purpleLineGraph from "../assets/Icons/purpleLineGraph.svg";
import logo from "../assets/Icons/logo.svg";
import Upload from "../assets/Icons/Upload.svg";
import jobSeeker from "../assets/Icons/Job_seeker.svg";
import hiring from "../assets/Icons/hiring.svg";
import hiring2 from "../assets/Icons/hiring-2.png";
import online from "../assets/Icons/online.svg";
import shortLogo from "../assets/Icons/favicon.svg";
import postNotes from "../assets/Icons/postNotes.png";
import loudspeaker from "../assets/Icons/loudspeaker.png";
import onboarding from "../assets/Icons/onboarding.png";
import redLineGraph from "../assets/Icons/redLineGraph.svg";
import review from "../assets/Icons/review.png";
import invite from "../assets/Icons/invite.png";
import authorIcon from "../assets/Icons/Author.svg";
import writer from "../assets/Icons/writer.svg";

const Images = {
  about,
  aboutW,
  adminAdvertiser,
  adminAuthor,
  adminEmployer,
  adminJob,
  advertiserbg1,
  advertiserbg3,
  author,
  authorW,
  authorbg1,
  authorIcon,
  articlesButton,
  backgroundImg,
  blog,
  cactus,
  comms,
  commsW,
  darkGreenLineGraph,
  denied,
  edit,
  employerBg1,
  error,
  greenLineGraph,
  heartbeat,
  hiring,
  hiring2,
  hospital,
  hospitalW,
  hourglass,
  invite,
  jobAlert,
  jobSeeker,
  jobSeekerImg,
  jobSeekerImgW,
  jobSeekerbg1,
  jobSeekerbg2,
  jobSeekerbg3,
  lightBulb,
  loginbg,
  logo,
  loudspeaker,
  notFound,
  onboarding,
  online,
  orangeBg,
  pause,
  postNotes,
  profile,
  profileAvatar,
  purpleBg,
  purpleLineGraph,
  redLineGraph,
  read,
  review,
  shortLogo,
  slider1,
  sliderW1,
  signup,
  underConstruction,
  Upload,
  whyPhoto,
  whyPhotoW,
  writer
};

export default Images;
