const colors = {
	//Custom palette
	backgroundGray: '#CCCCCC', // was originally #f2f2f2
	buttonGray: '#E6E6E6',
	blogBackground: '#B0ACAC',
	darkGreen: '#22B573',
	darkPurple: '#7F47DD',
	green: '#B0D157',
	gray: '#B8B9BD',
	hover: 'rgba(185, 77, 235, 0.04)',
	iconGray: '#9b9b9b',
	lightGreen: '#B1CF58',
	lightPurple: '#C18ED9',
	orange: '#DAAE4A',
	purple: '#B94DEB',
	red: '#F36A5A',
	textGray: '#444142',
	white: '#ffffff',
};
export default colors;
