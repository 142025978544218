import styled from 'styled-components';
import { FluidType } from './FluidType';

export default styled.h5`
  text-align: ${props => props.align || 'left'};
  color: ${props => props.color};
  font-family: 'Futura Std Light', sans-serif;
  margin: ${props => props.margin || 0};
  font-style: ${props => props.fontStyle || 'normal'};
  font-weight: ${props => props.fontWeight || 300};
  ${FluidType('16px', '18px', '320px', '1200px')}
`;
