import { combineReducers } from "redux";
import user from "./user";
import jobsReducer from "./job";
import blogsReducer from "./blog";
import advertsReducer from "./adverts";
import applicationReducer from "./application";
import adminReducer from "./admin";
import email from "./email";

function resetState(state = {}, action) {
  switch (action.type) {
    case "RESET_STORE":
      return {};
    default:
      return state;
  }
}

export default combineReducers({
  adverts: advertsReducer,
  applications: applicationReducer,
  blogs: blogsReducer,
  dashboard: adminReducer,
  jobs: jobsReducer,
  user,
  email,
  resetState
});
