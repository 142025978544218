import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

export const avatarStorage = filetype => firebase.storage().ref(filetype);

export const Avatar = {
  uploadAvatars: (filetype, filename) => {
    return avatarStorage(filetype).child(filename);
  },
  deleteAvatars: filePath => {
    return new Promise((resolve, reject) => {
      const storageRef = firebase.storage().ref();

      // Create a reference to the file to delete
      const imgRef = storageRef.child(filePath);

      imgRef
        .delete()
        .then(() => resolve())
        .catch(error => reject(error));
    });
  }
};

export default firebase;
