// import { NavLink } from 'react-router-dom';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import tag from 'blacklist-tag';

export const CleanNavLink = tag(NavLink, [
  'justifyContent',
  'alignItems',
  'flexDirection',
  'maxWidth',
  'renderTabs',
  'hasSubMenu',
  'childLinkActive'
]);
