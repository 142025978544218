import styled from "styled-components";
import { Card, Col, Input, Row, Select, AutoComplete } from "antd";
import { Colors, Metrics, Images } from "../../themes";
import { convertPixelsToRem } from "../../utils/helpers";

const { backgroundGray, green, textGray } = Colors;
const {
  padding: { largeResponsive, responsive },
  borderRadius: { large }
} = Metrics;
const { articlesButton } = Images;

export const VisionCard = styled.div`
  background: ${textGray};
  opacity: 0.79;
  color: white;
  height: 100%;
  padding: ${responsive} ${largeResponsive};
  font-size: ${convertPixelsToRem(25)};
  min-width: ${convertPixelsToRem(450)};
  @media (max-width: 770px) {
    width: ${props => props.smallWidth || "initial"} !important;
  }
  @media (max-width: 700px) {
    font-size: ${convertPixelsToRem(19)};
    min-width: unset;
  }
`;

export const GreenText = styled.span`
  color: ${green};
`;
export const ColoredText = styled.span`
  color: ${props => props.color};
`;
export const AuthInput = styled(Input)`
  .ant-input {
    border-width: 0 0 1px 0;
    border: 1px solid #ffffff;
    border-bottom: 1px solid #b94deb;
    border-radius: 0;
  }
  .ant-form-item-children {
    .ant-input {
      border-width: 0 0 1px 0;
      border: 1px solid #ffffff;
      border-bottom: 1px solid #b94deb;
      border-radius: 0;
    }
  }
`;
export const AuthPassword = styled(Input.Password)`
  .ant-input {
    border-width: 0 0 1px 0;
    border: 1px solid #ffffff;
    border-bottom: 1px solid #b94deb;
    border-radius: 0;
  }
  .ant-form-item-children {
    .ant-input {
      border-width: 0 0 1px 0;
      border: 1px solid #ffffff;
      border-bottom: 1px solid #b94deb;
      border-radius: 0;
    }
  }
`;
export const AuthSelect = styled(Select)`
  .ant-select-selection {
    border-width: 0 0 1px 0;
    border: 1px solid #ffffff;
    border-bottom: 1px solid #b94deb;
    border-radius: 0;
  }
`;
export const AuthAutoComplete = styled(AutoComplete)`
  .ant-input {
    border-width: 0 0 1px 0;
    border: 1px solid #ffffff;
    border-bottom: 1px solid #b94deb;
    border-radius: 0;
  }
`;
export const ReadButton = styled.div`
  background-image: url(${articlesButton});
  background-size: contain;
  background-repeat: no-repeat;
  width: ${convertPixelsToRem(200)};
  height: ${convertPixelsToRem(35)};
  font-size: ${convertPixelsToRem(18)};
  border-radius: ${convertPixelsToRem(4)};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
export const AdCard = styled(Card)`
  width: 100%;
  height: ${convertPixelsToRem(200)};
  margin-bottom: 10% !important;
  @media (max-width: 765px) {
    height: ${props => props.height || "15vh"};
  }
`;

export const AdvertImage = styled.div`
  background-image: ${props => `url(${props.src})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: auto;
  min-height: ${convertPixelsToRem(200)};
  max-height: ${convertPixelsToRem(250)};
  @media (max-width: 765px) {
    height: 15vh;
    min-height: unset;
    max-height: unset;
  }
`;

export const InfoRow = styled(Row)`
  background: ${backgroundGray};
  padding: ${responsive} ${largeResponsive};
  border-radius: ${large};
  margin: 0 !important;
`;
export const ArticleDiv = styled.div`
  width: 100%;
  border-radius: 2%;
  margin-bottom: 5%;
  height: ${props => props.height || "15vh"};
  background: linear-gradient(to right, rgb(0, 0, 0, 0.35), rgb(0, 0, 0, 0.9)),
    url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: ${convertPixelsToRem(10)};
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const ArticleCard = styled(Card)`
  width: 100%;
  max-width: ${convertPixelsToRem(450)};
  margin-bottom: 10% !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  @media (max-width: 765px) {
    height: ${props => (props.height ? props.height : convertPixelsToRem(100))};
  }
`;
export const Snippet = styled.div`
  margin-bottom: 1em;
  font-family: Proxima !important;
  color: ${props => props.color} !important;
  overflow: hidden;
  text-overflow: ellipsis;
  height: ${props => props.height};
`;
export const ArticleCover = styled.div`
  background-image: url(${props => props.src});
  background-repeat: no repeat;
  background-position: center;
  background-size: cover;
  height: ${convertPixelsToRem(80)};
`;

export const FormCol = styled(Col)`
  margin-top: ${responsive};
  overflow: scroll;
`;
