import * as React from "react";
import styled from "styled-components";

import { PageWrap } from "./StyledElements";
import { Colors } from "../../themes";
import { HealthHunterContext } from "../ContextProvider";

import HeaderContainer from "../Header";
import FooterContainer from "../Footer";
const RenderWrapper = styled.div`
  background: ${Colors.backgroundGray};
  width: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;
class Sidebar extends React.Component {
  static contextType = HealthHunterContext;
  static defaultProps = {
    navItems: []
  };
  context;

  render() {
    const { navItems, color, children } = this.props;
    const { context } = this;
    return (
      <PageWrap>
        <RenderWrapper>
          <HeaderContainer
            {...this.props}
            context={context}
            navItems={navItems}
            color={color}
          />
          {children}
          <FooterContainer position="unset" />
        </RenderWrapper>
      </PageWrap>
    );
  }
}

export default Sidebar;
