import Loadable from "react-loadable";
import Loading from "./Loading";

const AsyncLogin = Loadable({
  loader: () => import("../containers/Login"),
  loading: Loading
});
const AsyncSignup = Loadable({
  loader: () => import("../containers/Signup"),
  loading: Loading
});

const AsyncAdvertiser = Loadable({
  loader: () => import("../containers/Signup/Advertisers"),
  loading: Loading
});

const AsyncAuthor = Loadable({
  loader: () => import("../containers/Signup/Bloggers"),
  loading: Loading
});

const AsyncEmployer = Loadable({
  loader: () => import("../containers/Signup/Employers"),
  loading: Loading
});

const AsyncSubscribe = Loadable({
  loader: () => import("../containers/Signup/JobSeeker"),
  loading: Loading
});

// const AsyncVerifyCode = Loadable({
//   loader: () => import('../containers/Authentication/VerifyCode'),
//   loading: Loading
// });

// const AsyncVerifyEmail = Loadable({
//   loader: () => import('../containers/Authentication/VerifyEmail'),
//   loading: Loading
// });
//
const AsyncForgotPassword = Loadable({
  loader: () => import("../containers/ForgotPassword"),
  loading: Loading
});

// const AsyncResetPassword = Loadable({
//   loader: () => import('../containers/Authentication/ResetPassword'),
//   loading: Loading
// });

let AuthRoutes = [
  {
    title: "Login",
    path: "/login",
    component: AsyncLogin,
    exact: true
  },
  {
    title: "Signup",
    path: "/signup",
    component: AsyncSignup,
    exact: true
  },
  {
    title: "Advertisers",
    path: "/signup/advertiser",
    component: AsyncAdvertiser,
    exact: true
  },
  {
    title: "Author",
    path: "/signup/author",
    component: AsyncAuthor,
    exact: true
  },
  {
    title: "Employers",
    path: "/signup/employer",
    component: AsyncEmployer,
    exact: true
  },
  {
    title: "Subscribe",
    path: "/signup/jobSeeker",
    component: AsyncSubscribe,
    exact: true
  },
  // {
  //   title: 'Verify',
  //   path: '/verifyCode',
  //   component: AsyncVerifyCode,
  //   exact: true
  // },
  // {
  //   title: 'Signup',
  //   path: '/accept-invitation/:profileId/:invitationId',
  //   component: AsyncSignup,
  //   exact: true
  // },
  // {
  //   title: 'Verify Email',
  //   path: '/verify-email/:verificationId',
  //   component: AsyncVerifyEmail,
  //   exact: true
  // },
  {
    title: "ForgotPassword",
    path: "/forgot-password",
    component: AsyncForgotPassword,
    exact: true
  }
  // {
  //   title: 'ResetPassword',
  //   path: '/reset-password/:resetId',
  //   component: AsyncResetPassword,
  //   exact: true
  // }
];
export default AuthRoutes;
