// style-utils.js

export const StripUnit = (value1: string, value2: string) => {
  let strippedValue1 = parseInt(value1);
  let strippedValue2 = parseInt(value2);
  let sum = strippedValue1 - strippedValue2;
  return sum;
};

export function FluidType(
  minFontSize: string,
  maxFontSize: string,
  minWidth: string,
  maxWidth: string
) {
  return `
  @media screen and (min-width: ${minWidth}) {
    font-size: calc(${minFontSize} + ${StripUnit(
    maxFontSize,
    minFontSize
  )} * ((100vw - ${minWidth}) / ${StripUnit(maxWidth, minWidth)}));
  }
  @media screen and (min-width: ${maxWidth}) {
    font-size: ${maxFontSize};
  }
  `;
}
