import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../themes';

const { textGray } = Colors
const HamburgerIcon = styled.div`
  width: 2em;
  height: 2em;
  background: transparent;
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  & span {
    width: 70%;
    height: 2px;
    border-radius: 5px;
    transform-origin: center left;
    opacity: ${(props) => (props.open ? 0.7 : 1)};
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    background-color: ${(props) => props.color || textGray};
    &:nth-child(1) {
      transform: ${(props) =>
        !props.open
          ? 'translateY(-0.12em) translateX(0.8em) rotate(45deg) scaleX(0.6)'
          : 'translate3d(0,0,0) rotate(0deg)'};
    }
    &:nth-child(2) {
      margin: 5px 0;
    }
    &:nth-child(3) {
      transform: ${(props) =>
        !props.open
          ? 'translateY(0.12em) translateX(0.8em) rotate(-45deg) scaleX(0.6)'
          : 'translate3d(0,0,0) rotate(0deg)'};
    }
  }
  &:hover {
    cursor: pointer;
  }
  &:hover span {
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    opacity: 1;
    &:nth-child(1) {
      transform: ${(props) =>
        !props.open
          ? 'translateY(-0.12em) translateX(0.8em) rotate(45deg) scaleX(0.6)'
          : 'scaleX(1.1)'};
    }
    &:nth-child(2) {
      ${(props) => props.open && 'transform: scaleX(0.8)'};
    }
    &:nth-child(3) {
      transform: ${(props) =>
        !props.open
          ? 'translateY(0.12em) translateX(0.8em) rotate(-45deg) scaleX(0.6)'
          : 'scaleX(0.95)'};
    }
  }
`;

export default ({ open, onClick }) => {
  return (
    <HamburgerIcon open={open} onClick={onClick}>
      <span />
      <span />
      <span />
    </HamburgerIcon>
  );
};
