import React from 'react';
import { connect } from "react-redux"

const defaultValues = {
  drawerOpen: true,
  toggleDrawer: () => null
};

export const HealthHunterContext = React.createContext(defaultValues);

class ContextProvider extends React.Component {
  state = {
    drawerOpen: false,
    toggleDrawer: () =>
      this.setState({
        drawerOpen: !this.state.drawerOpen
      }),
    role: this.props.role
  };

  render() {
    return (
      <HealthHunterContext.Provider value={this.state}>
        {this.props.children}
      </HealthHunterContext.Provider>
    );
  }
}
const mapStateToProps = state => ({
  loggedIn: !!state.user.currentUser,
  user: state.user.currentUser && state.user.currentUser.name,
  role: state.user.currentUser && state.user.currentUser.role
})

export default connect(mapStateToProps)(ContextProvider);
