export default function adverts(state = {}, action) {
  switch (action.type) {
    case "CREATE_ADVERT_REQUEST":
    case "VIEW_ADVERTS_REQUEST":
    case "DELETE_ADVERT_REQUEST":
    case "GET_ADVERT_REQUEST":
    case "EDIT_ADVERT_REQUEST":
      return {
        ...state,
        message: null,
        error: null,
        loading: true
      };
    case "CREATE_ADVERT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        message: `Your advert has been successfully created`
      };
    case "CREATE_ADVERT_FAILURE":
      return {
        ...state,
        loading: false,
        message: null,
        error: action.err
      };
    case "VIEW_ADVERTS_SUCCESS":
      return Object.assign({}, state, {
        all: action.adverts,
        loading: false
      });
    case "VIEW_ADVERTS_FAILURE":
      return Object.assign({}, state, {
        error: action.err,
        loading: false
      });
    case "VIEW_ADVERT":
      return Object.assign({}, state, {
        current: action.currentAdvert,
        loading: false,
        error: null
      });
    case "GET_ADVERT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        current: action.advert
      };
    case "GET_ADVERT_FAILURE":
      return {
        ...state,
        loading: false,
        message: null,
        error: action.err
      };
    case "EDIT_ADVERT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        message: `Your advert has been successfully updated`
      };
    case "EDIT_ADVERT_FAILURE":
      return {
        ...state,
        loading: false,
        message: null,
        error: action.err
      };
    case "DELETE_ADVERT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        message: `The advert has been successfully deleted`
      };
    case "DELETE_ADVERT_FAILURE":
      return {
        ...state,
        loading: false,
        message: null,
        error: action.err
      };
    case "ADVERT_SEARCH_SUCCESS":
      return Object.assign({}, state, {
        results: action.adverts
      });
    case "ADVERT_SEARCH_FAILURE":
      return Object.assign({}, state, {
        results: action.err
      });
    case "RESET_STORE":
      return {};
    default:
      return state;
  }
}
