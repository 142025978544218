import Loadable from 'react-loadable';
import Loading from './Loading';

const AsyncDashboard = Loadable({
    loader: () => import('../containers/Admin'),
    loading: Loading
});

const AsyncJobs = Loadable({
    loader: () => import('../containers/Admin/Jobs'),
    loading: Loading
});

// const AsyncEditJob = Loadable({
//     loader: () => import('../containers/Admin/Jobs/EditJob'),
//     loading: Loading
// });

const AsyncAdvertisement = Loadable({
    loader: () => import('../containers/Admin/Adverts'),
    loading: Loading
});

// const AsyncEditAdvertisement = Loadable({
//     loader: () => import('../containers/Admin/Adverts/EditAdverts'),
//     loading: Loading
// });

// const AsyncAdDetail = Loadable({
//   loader: () => import('../containers/AdDetail'),
//   loading: Loading
// });

const AsyncBlog = Loadable({
    loader: () => import('../containers/Admin/Blogs'),
    loading: Loading
});

// const AsyncEditBlog = Loadable({
//   loader: () => import('../containers/Admin/Blog/EditBlog'),
//   loading: Loading
// });

const AsyncUsers = Loadable({
  loader: () => import('../containers/Admin/User'),
  loading: Loading
});

// const AsyncNewUser = Loadable({
//   loader: () => import('../containers/User/NewUser'),
//   loading: Loading
// });

// const AsyncProfile = Loadable({
//   loader: () => import('../containers/Profile'),
//   loading: Loading
// });

let Routes = [
    {
        title: 'Dashboard',
        path: '/admin/dashboard',
        component: AsyncDashboard,
        exact: true,
        role: 'all'
    },
    {
        title: 'Jobs',
        path: '/admin/jobs',
        component: AsyncJobs,
        exact: true,
        role: 'employer'
    },
    {
        title: 'Advertisement',
        path: '/admin/adverts',
        component: AsyncAdvertisement,
        exact: true,
        role: 'advertiser'
    },
    // {
    //     title: 'Advertisement',
    //     path: '/admin/adverts/edit/',
    //     component: AsyncEditAdvertisement,
    //     exact: true
    // },
    {
        title: 'Blog',
        path: '/admin/blogs',
        component: AsyncBlog,
        exact: true,
        role: 'author'
    },
    // {
    //   title: 'Add Blog',
    //   path: '/blog/new',
    //   component: AsyncNewBlog,
    //   exact: true
    // },
    // {
    //   title: 'Edit Blog',
    //   path: '/blog/:blogId',
    //   component: AsyncEditBlog,
    //   exact: true
    // },
    {
      title: 'Users',
      path: '/admin/users',
      component: AsyncUsers,
      exact: true
    },
    // {
    //   title: 'New User',
    //   path: '/users/new',
    //   component: AsyncNewUser,
    //   exact: true
    // },
    // {
    //   title: 'Edit User',
    //   path: '/users/:listingId',
    //   component: AsyncEditUser,
    //   exact: true
    // },
];

export default Routes;
