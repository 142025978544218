import styled from 'styled-components';
import { FluidType } from './FluidType';

import { Colors } from '../../themes';

export default styled.p`
  text-align: ${props => props.align || 'left'};
  color: ${props => props.color || Colors.textGray};
  font-family: 'Arial', sans-serif;
  font-weight: ${props => props.fontWeight || '300'};
  margin: ${props => props.margin || 0};
  padding: ${props => props.padding || 0};
  width: 100%;
  ${FluidType('14px', '16px', '320px', '1200px')};
  &:hover {
    cursor: ${props => props.hover && 'pointer'};
  }
`;
