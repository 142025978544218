import { createBrowserHistory } from "history";
import { filter, isEmpty } from "lodash";
export const history = createBrowserHistory();

export const getArrayFromObject = (object) => {
  let arrayFromObject = [];

  if (object && typeof object === "object") {
    Object.keys(object).forEach((key) => {
      arrayFromObject.push({ accessor: key, value: object[key] });
    });
  }

  return arrayFromObject;
};

export const convertPixelsToRem = (unitValue) => {
  return `${(unitValue / 16).toFixed(2)}rem`;
};

export const formatEnums = (value) => value.replace("_", " ");

export const uniqueID = () => {
  function chr4() {
    return Math.random().toString(16).slice(-4);
  }
  return (
    chr4() +
    chr4() +
    "-" +
    chr4() +
    "-" +
    chr4() +
    "-" +
    chr4() +
    "-" +
    chr4() +
    chr4() +
    chr4()
  );
};

export const filterData = (entries, filterType, searchTerm) => {
  if (entries && filterType && searchTerm) {
    return filter(entries, (entry) => entry[filterType] === searchTerm);
  }

  return entries;
};

/**
 *
 * @param {object} entries
 * @param {array} filterTypes
 * @param {array} searchTerms
 */
export const multipleFiltersForData = (entries, filterTypes, searchTerms) => {
  if (entries && !isEmpty(filterTypes) && !isEmpty(searchTerms)) {
    return filter(
      entries,
      (entry) =>
        entry[filterTypes[0]] === searchTerms[0] &&
        entry[filterTypes[1]] === searchTerms[1] &&
        entry[filterTypes[2]] === searchTerms[2]
    );
  }

  return entries;
};

// eslint-disable-next-line no-useless-escape
export const validUrl = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

// Truncate a paragraph
export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

/**
 * Sort an array of object by date updated
 * @array - an array of objects with the field date_updated */
export const sortByDateUpdated = (array) =>
  array.sort(function (a, b) {
    let dateA = new Date(a.date_updated).getTime();
    let dateB = new Date(b.date_updated).getTime();
    return dateA < dateB ? 1 : -1;
  });
