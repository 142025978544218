import styled from 'styled-components';
import { FluidType } from './FluidType';

export default styled.h4`
  text-align: ${props => props.align || 'left'};
  color: ${props => props.color};
  font-family: ${props =>
    props.customFont
      ? `${props.customFont}, sans-serif`
      : 'Futura Std Light, sans-serif;'};
  margin: ${props => props.margin || 0};
  font-style: ${props => props.fontStyle || 'normal'};
  font-weight: ${props => props.fontWeight || 300};
  ${FluidType('16px', '20px', '320px', '1200px')}
`;
