import React from "react";
import SidebarContainer from "../../components/PublicSidebar";
import { Colors } from "../../themes";

const { textGray } = Colors;

export const NAV_ITEMS = [
  {
    to: "/#about",
    title: "About Us"
  },
  {
    to: "/articles",
    title: "Articles"
  },
  {
    to: "/signup/employer",
    title: "For Employers"
  },
  {
    to: "/signup/advertiser",
    title: "For Advertisers"
  },
  {
    to: "/signup/author",
    title: "For Authors"
  },
  {
    to: "/signup/jobSeeker",
    title: "Job seekers"
  }
];
const PublicSidebar = props => {
  const { children } = props;
  return (
    <React.Fragment>
      <SidebarContainer
        navItems={NAV_ITEMS}
        color={textGray}
        children={children}
      />
    </React.Fragment>
  );
};

export default PublicSidebar;
