import styled from 'styled-components';
import { FluidType } from './FluidType';

const NavItem = styled.span`
  text-align: ${props => props.align || 'left'};
  color: ${props => props.color};
  font-family: 'PT Sans', sans-serif;
  margin: ${props => props.margin || 0};
  font-weight: ${props => props.fontWeight || '300'};
  ${FluidType('12px', '15px', '320px', '1200px')}
`;

export default NavItem;
