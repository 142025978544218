import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Images } from "../../themes";
// import { H3, H5 } from '/../components/Text';

const Image = styled.img`
  margin: 50px;
  width: 35%;
  height: 35%;
`;

const Layout = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default () => (
  <Layout>
    <h3>Oops, an error has occurred!</h3>
    <Image src={Images.error} alt="Error" />
    <h5>It has been reported and will be attended to.</h5>
    <Link to="/dashboard">
      <p>Click here to return to the home page</p>
    </Link>
    <Helmet title="Error!" />
  </Layout>
);
