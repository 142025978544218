import styled from 'styled-components';

export default styled.span`
  font-size: 22px;
  text-align: ${props => props.align || 'left'};
  color: ${props => props.color};
  font-family: 'Futura Std Heavy', sans-serif;
  margin: ${(props: any) => props.margin || 0};
  font-weight: ${props => props.weight || 'bold'};
`;
