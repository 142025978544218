import request from "superagent";
import { isEmpty } from "lodash";
import { notification } from "antd";

const url = process.env.REACT_APP_BACKEND;

export const createUserRequest = () => ({
  type: "CREATE_USER_REQUEST"
});
export const loadSignup = () => ({
  type: "LOAD_SIGNUP"
});

export const createUserSuccess = user => ({
  type: "CREATE_USER_SUCCESS",
  user
});

export const createUserFailure = err => ({
  type: "CREATE_USER_FAILURE",
  err
});

export function createUser(user) {
  return async dispatch => {
    await dispatch(createUserRequest());
    request
      .post(`${url}/api/user`)
      .send({ ...user })
      .then(
        res => {
          dispatch(createUserSuccess(res.body));
          if (user.role !== "user") {
            return notification.success({
              message:
                "Great! Thank you for signing up with Health Hunter Africa, Your account will be approved shortly via email notification"
            });
          }
        },
        err => {
          if (user.role === "user" && err.message === "Conflict") {
            dispatch(createUserFailure("You are already subscribed"));
          } else if (typeof err !== "string") {
            dispatch(createUserFailure(err.message));
          } else {
            dispatch(createUserFailure(err));
          }
        }
      );
  };
}
export const loginUserRequest = () => ({
  type: "LOGIN_USER_REQUEST"
});
export const loginUserSuccess = response => ({
  type: "LOGIN_USER_SUCCESS",
  user: response.user,
  message: response.message
});
export const loginUserFailure = err => ({
  type: "LOGIN_USER_FAILURE",
  error: err.message
});

export function loginUser(user, history, actions) {
  const previousPath = history.location.state && history.location.state.from;
  // user is an object with email
  return dispatch => {
    dispatch(loginUserRequest());
    request
      .get(`${url}/api/user`)
      .query(user)
      .then(res => {
        const setToken = async () =>
          await sessionStorage.setItem("AuthToken", res.headers.authorization);

        setToken().then(async () => {
          await dispatch(loginUserSuccess(res.body));
          const { role } = res.body.user;

          if (role) {
            if (role === "advertiser") {
              history.replace("/adverts");
            } else if (role === "employer") {
              history.replace("/jobs");
            } else if (role === "blogger") {
              history.replace("/blogs");
            } else if (role === "admin") {
              history.replace("/admin/dashboard");
            } else if (role === "user") {
               
                if (previousPath) {
                  //on login go back to page user was on 
                  history.replace(previousPath);
                }else{
                  history.replace("/");
                }  
            }
          }
        });
      })
      .catch(err => {
        actions.setSubmitting(false);
        if (err && err.response) {
          if (isEmpty(err.response.body)) {
            return dispatch(loginUserFailure(err));
          }
          return dispatch(loginUserFailure(err.response.body));
        }
      });
  };
}

export const getUserRequest = () => ({
  type: "GET_USER_REQUEST"
});
export const getUserSuccess = response => ({
  type: "GET_USER_SUCCESS",
  user: response.user,
  message: response.message
});
export const getUserFailure = err => ({
  type: "GET_USER_FAILURE",
  error: err.message
});

export function getUser(user, history) {
  // user is an object with email and password
  return dispatch => {
    dispatch(getUserRequest());
    request
      .get(`${url}/api/user`)
      .query(user)
      .then(res => {
        dispatch(getUserSuccess(res.body));
      })
      .catch(err => {
        return dispatch(getUserFailure(err.response.body));
      });
  };
}

export const getUsersRequest = () => ({
  type: "GET_USERS_REQUEST"
});

export const getUsersSuccess = users => ({
  type: "GET_USERS_SUCCESS",
  users
});

export const getUsersFailure = err => ({
  type: "GET_USERS_FAILURE",
  err
});

export function viewUsers(id) {
  const token = sessionStorage.getItem("AuthToken");

  return dispatch => {
    dispatch(getUserRequest());
    request
      .get(`${url}/api/user`)
      .set("Authorization", token)
      .then(res => {
        dispatch(getUsersSuccess(res.body));
      })
      .catch(err => {
        dispatch(getUsersFailure(err));
      });
  };
}

export const editUserRequest = () => ({
  type: "EDIT_USER_REQUEST"
});
export const editUserSuccess = user => ({
  type: "EDIT_USER_SUCCESS",
  user
});
export const editUserFailure = err => ({
  type: "EDIT_USER_FAILURE",
  err
});

export function editUser(user, history) {
  const token = sessionStorage.getItem("AuthToken");

  return dispatch => {
    dispatch(editUserRequest());
    request
      .put(`${url}/api/user`)
      .set("Authorization", token)
      .send(user)
      .then(res => {
        dispatch(editUserSuccess(res.body));
        const { role } = user;
        if (role === "admin") {
          history.push("/admin/dashboard");
        } else {
          history.push("/settings");
        }
      })
      .catch(err => {
        dispatch(editUserFailure(err));
      });
  };
}

export const resetPasswordRequest = () => ({
  type: "RESET_PASSWORD_REQUEST"
});
export const resetPasswordSuccess = user => ({
  type: "RESET_PASSWORD_SUCCESS",
  user
});
export const resetPasswordFailure = err => ({
  type: "RESET_PASSWORD_FAILURE",
  err
});

export function resetPassword(user, history) {
  const token = sessionStorage.getItem("AuthToken");

  return dispatch => {
    dispatch(resetPasswordRequest());
    request
      .put(`${url}/api/user/reset`)
      .set("Authorization", token)
      .send(user)
      .then(res => {
        dispatch(resetPasswordSuccess(res.body));
        history.push("/settings");
      })
      .catch(err => {
        dispatch(resetPasswordFailure(err));
      });
  };
}

export const verifyPasswordRequest = () => ({
  type: "VERIFY_PASSWORD_REQUEST"
});
export const verifyPasswordSuccess = response => ({
  type: "VERIFY_PASSWORD_SUCCESS",
  user: response.user,
  message: response.message
});
export const verifyPasswordFailure = err => ({
  type: "VERIFY_PASSWORD_FAILURE",
  error: err.message
});

export function verifyPassword(user, password) {
  const token = sessionStorage.getItem("AuthToken");

  // user is an object with id and password
  return dispatch => {
    if (!token) return window.location.href("/logout");
    dispatch(verifyPasswordRequest());
    request
      .post(`${url}/api/user/verify`)
      .set("Authorization", token)
      .send({ id: user._id, password })
      .then(res => {
        return dispatch(verifyPasswordSuccess(res.body));
      })
      .catch(err => {
        if (err.response) {
          return dispatch(verifyPasswordFailure(err.response.body));
        }
        return dispatch(verifyPasswordFailure(err));
      });
  };
}
export const logoutUserRequest = () => ({
  type: "RESET_STORE"
});

export function logoutUser() {
  return dispatch => {
    sessionStorage.removeItem("AuthToken");
    sessionStorage.clear();
    dispatch(logoutUserRequest());
  };
}
