export default function admin (state={}, action) {
    switch(action.type) {
        case "LOAD_DASHBOARD_REQUEST":
            return {
                ...state,
                loading: true,
                error: undefined,
                message: null,
            }
        case "LOAD_DASHBOARD_SUCCESS":
            return {
                ...state,
                loading: false,
                error: undefined,
                message: null,
            }
        case "LOAD_DASHBOARD_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.err,
                message: action.err.message,
            }
        case "APPROVE_ADVERT_REQUEST":
            return {
                ...state,
                loading: true,
                error: undefined,
                message: null,
            } 
        case "APPROVE_ADVERT_SUCCESS":
            return {
                ...state,
                loading: false,
                error: undefined,
                message: null,
            }
        case "APPROVE_ADVERT_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.err,
                message: action.err.message,
            }
        case "MAKE_ADMIN_REQUEST":
            return {
                ...state,
                loading: true,
                error: undefined,
                message: null,
            }
        case "MAKE_ADMIN_SUCCESS":
            return {
                ...state,
                loading: false,
                error: undefined,
                message: null,
            }
        case "MAKE_ADMIN_FAILURE":
            return {
                ...state,
                loading: false,
                error: action.err,
                message: action.err.message,
            }
        case 'DELETE_USER_REQUEST':
            return {
                ...state,
                message: null,
                error: null,
                loading: true,
            }
        case 'DELETE_USER_SUCCESS':
            return {
                ...state,
                loading: false,
                error: null,
                message: `${action.user.name} has been successfully deleted`
            }
        case 'DELETE_USER_FAILURE':
            return {
                ...state,
                loading: false,
                message: null,
                error: action.err
            }
        default:
            return state
    }
}