import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { PageWrap } from "./StyledElements";
import { Images, Colors, Metrics } from "../../themes";
import { HealthHunterContext } from "../ContextProvider";

import Header from "../Header";
import SideBarLink from "./SideBarLink";
import HamburgerIcon from "./HamburgerIcon";
import { Logo, LogoCont, SideBarWrapper } from "./StyledElements";

const {
  padding: { largeResponsive }
} = Metrics;
const RenderWrapper = styled.div`
  background: ${Colors.backgroundGray};
  background-image: url(${Images.backgroundImg});
  width: 100%;
  display: flex;
  min-height: 100vh;
  padding-left: 60px;
  flex-direction: column;
  overflow: scroll;
  padding-bottom: ${largeResponsive};
`;
class Sidebar extends React.Component {
  static contextType = HealthHunterContext;
  static defaultProps = {
    navItems: []
  };
  context;

  render() {
    const { navItems, color, children, history, role } = this.props;
    const { context } = this;
    return (
      <PageWrap>
        <SideBarWrapper
          className="custom-sidebar"
          open={context.drawerOpen}
          color={color}
        >
          <LogoCont open={context.drawerOpen}>
            <HamburgerIcon
              onClick={context.toggleDrawer}
              open={context.drawerOpen}
            />
            <span onClick={() => history.push("/")}>
              <Logo
                open={context.drawerOpen}
                src={Images.shortLogo}
                alt="logo"
              />
            </span>
          </LogoCont>
          {navItems &&
            navItems.length > 0 &&
            navItems.map(
              i =>
                (role === i.role || i.role === "all") && (
                  <SideBarLink
                    to={i.to}
                    key={i.title}
                    icon={i.icon}
                    title={i.title}
                    subMenu={i.subMenu}
                    color={color || "white"}
                    open={context.drawerOpen}
                  />
                )
            )}
        </SideBarWrapper>
        <RenderWrapper>
          <Header {...this.props} loggedIn={true} />
          {children}
        </RenderWrapper>
      </PageWrap>
    );
  }
}
const mapStateToProps = state => {
  return {
    role: state.user.currentUser.role
  };
};
export default connect(mapStateToProps)(Sidebar);
