import * as React from 'react';
import { Spring } from 'react-spring/renderprops.cjs';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1000;
  max-height: 0;
  overflow: hidden;
`;

const OuterWrapper = styled.div`
  display: flex;
`;

const InnerWrapper = styled.div`
  width: 100%;
`;

class Collapse extends React.Component {
  static defaultProps = {
    collapsedHeight: 0,
    open: false
  };

  outerWrapper = React.createRef();

  render() {
    const { children, open, style } = this.props;
    const node = this.outerWrapper.current;
    const outerWrapperHeight = node ? node.clientHeight : 0;
    // TODO: Add easing function.
    return (
      <Spring
        config={{ tension: 200, friction: 30 }}
        from={{ maxHeight: !open ? outerWrapperHeight : 0 }}
        to={{ maxHeight: !open ? 0 : outerWrapperHeight }}
      >
        {styleProps => (
          <Container style={{ ...styleProps, ...style }}>
            <OuterWrapper ref={this.outerWrapper}>
              <InnerWrapper>{children}</InnerWrapper>
            </OuterWrapper>
          </Container>
        )}
      </Spring>
    );
  }
}

export default Collapse;
