import styled from 'styled-components';
import { Colors } from '../../themes';

const Label = styled.label`
  color: ${Colors.textGray};
  font-family: 'Arial';
  font-size: 14px;
  font-weight: bold;
  padding: ${props => props.padding || 0};
  margin-bottom: ${props => props.mb || 0};
`;
export default Label;
