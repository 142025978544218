// SideBarLink Elements
import { ChevronDown } from 'react-feather';
import styled from 'styled-components';
import { Colors } from '../../themes';

import { SP } from '../Text';
import Collapse from './Collapse';
import { CleanNavLink } from './CleanNavLink';

const { textGray, white } = Colors;
const PseudoStyles = `
  & > div {
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: #009bda;
    border-top-right-radius: 5px;
  }
  & > svg {
    color: #009bda !important;
    fill: #009bda !important;
  }`;

const RadiusStyles = `
  &:before {
    border-bottom-right-radius: 5px;
    transition: all 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }`;

export const Tooltip = styled.div`
  opacity: 0;
  left: 70px;
  display: flex;
  font-size: 13px;
  border-radius: 5px;
  visibility: hidden;
  position: absolute;
  align-items: center;
  justify-content: center;
  transform: translateX(10px);
  background-color: ${textGray};
  & p {
    margin: 0 !important;
    color: white;
    text-transform: uppercase;
  }
  &:after {
    top: 50%;
    width: 0;
    margin: 0;
    height: 0;
    left: -5px;
    content: '';
    line-height: 0;
    position: absolute;
    transform: translateY(-50%);
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid ${textGray};
  }
`;

export const NavItemWrapper = styled.div`
  flex: 1;
  height: 50px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  white-space: nowrap;
  background: transparent;
  justify-content: flex-start;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  &:hover {
    background: ${props => (props.open ? `${white}` : 'transparent')};
  }
  &:hover ${Tooltip} {
    opacity: 1;
    width: auto;
    padding: 10px;
    visibility: visible;
    transform: translateX(0);
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
`;

export const StyledLink = styled(CleanNavLink)`
  display: flex;
  align-self: stretch;
  text-decoration: none;
  ${props => !props.open && RadiusStyles};
  ${props => props.childLinkActive && PseudoStyles};
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  &.active-nav-link > div ${SP} {
    color: ${white};
  }
  &.active-nav-link ${NavItemWrapper} {
    background-color: ${textGray};
  }
  &:hover ${SP} {
    color: ${white};
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  &:hover ${NavItemWrapper} {
    background-color: ${textGray};
  }
`;

export const StyledSubMenuLink = styled(CleanNavLink)`
  width: 100%;
  text-decoration: none;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  &:hover {
    background-color: ${textGray};
  }
  &:hover ${SP} {
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    color: ${white};
  }
`;

export const StyledCollapse = styled(Collapse)`
  width: 100%;
  position: relative;
  overflow: ${props =>
    props.open && !props.sidebarOpen && 'visible !important'};
  span {
    font-size: 14px !important;
  }
  .nav-item__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    text-indent: 15%;
    a {
      width: 100%;
      ${props =>
        !props.open &&
        `
        display: flex;
        justify-content: center;
        `};
    }
  }
  & > svg {
    color: white !important;
  }
`;

export const StyledExpand = styled(ChevronDown)`
  right: 15px;
  position: absolute;
  opacity: ${props => (props.open ? 1 : 0)};
  visibility: ${props => (props.open ? 'visible' : 'hidden')};
  color: ${white};
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) !important;
  transform: ${props => (props.expanded ? 'rotateX(180deg)' : 'rotateX(0deg)')};
`;

export const LetterAvatar = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  opacity: ${props => (props.open ? 0 : 1)};
  width: ${props => (props.open ? 0 : '100%')};
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  color: ${white};
  &:hover ${Tooltip} {
    opacity: 1;
    width: auto;
    visibility: visible;
    transform: translateX(0);
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
`;

export const P = styled.p`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: ${textGray};
`;
