let config = {
  apiKey: process.env.REACT_APP_Firebase_key,
  authDomain: "health-hunter-africa.firebaseapp.com",
  databaseURL: "https://health-hunter-africa.firebaseio.com",
  projectId: process.env.REACT_APP_Project_id,
  storageBucket: "health-hunter-africa.appspot.com",
  messagingSenderId: process.env.REACT_APP_MESSAGE_ID
};

export default config;
