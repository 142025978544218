import H1 from './H1';
import H2 from './H2';
import H3 from './H3';
import H4 from './H4';
import H5 from './H5';
import P from './P';
import SP from './SP';
import SP2 from './SP2';
import Label from './Label';
import NavItem from './NavItem';
import PreTitle from './PreTitle';
import TextContainer from './TextContainer';
import NotificationText from './NotificationText';

export {
  H1,
  H2,
  H3,
  H4,
  H5,
  P,
  SP,
  SP2,
  Label,
  PreTitle,
  NavItem,
  TextContainer,
  NotificationText
};
