export default function jobs(state = {}, action) {
  switch (action.type) {
    case "CREATE_JOB_REQUEST":
    case "VIEW_JOBS_REQUEST":
    case "GET_JOB_REQUEST":
    case "EDIT_JOB_REQUEST":
    case "DELETE_JOB_REQUEST":
    case "JOB_SEARCH_REQUEST":
      return {
        ...state,
        message: null,
        error: null,
        loading: true
      };
    case "CREATE_JOB_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        message: `Your job has been successfully created`
      };
    case "CREATE_JOB_FAILURE":
      return {
        ...state,
        loading: false,
        message: null,
        error: action.err
      };
    case "VIEW_JOBS_SUCCESS":
      return Object.assign({}, state, {
        all: action.jobs,
        loading: false
      });
    case "VIEW_JOBS_FAILURE":
      return Object.assign({}, state, {
        error: action.err,
        loading: false
      });
    case "VIEW_JOB":
      return Object.assign({}, state, {
        current: action.currentJob,
        loading: false
      });
    case "GET_JOB_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        current: action.job
      };
    case "GET_JOB_FAILURE":
      return {
        ...state,
        loading: false,
        message: null,
        error: action.err
      };
    case "EDIT_JOB_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        message: `Your job has been successfully updated`
      };
    case "EDIT_JOB_FAILURE":
      return {
        ...state,
        loading: false,
        message: null,
        error: action.err
      };
    case "DELETE_JOB_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        message: `The job has been successfully deleted`
      };
    case "DELETE_JOB_FAILURE":
      return {
        ...state,
        loading: false,
        message: null,
        error: action.err
      };
    case "JOB_SEARCH_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        results: action.jobs
      };
    case "JOB_SEARCH_FAILURE":
      return {
        ...state,
        loading: false,
        err: action.err
      };
    case "RESET_STORE":
      return {};
    default:
      return state;
  }
}
