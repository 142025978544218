export default function applications(
  state = { loading: false, sent: false },
  action
) {
  switch (action.type) {
    case "VIEW_APPLICATION_REQUEST":
      return Object.assign({}, state, {
        current: action.payload,
        error: null
      });
    case "VIEW_APPLICATIONS_SUCCESS":
      return Object.assign({}, state, {
        all: action.payload,
        error: null
      });
    case "VIEW_APPLICATIONS_FAILURE":
      return Object.assign({}, state, {
        error: action.payload
      });
    case "SEND_APPLICATION_REQUEST":
    case "RESEND_APPLICATION_REQUEST":
      return Object.assign({}, state, {
        message: null,
        loading: true,
        sent: false
      });
    case "SEND_APPLICATION_SUCCESS":
    case "RESEND_APPLICATION_SUCCESS":
      return Object.assign({}, state, {
        ...action.payload,
        loading: false,
        sent: true
      });
    case "SEND_APPLICATION_FAILURE":
    case "RESEND_APPLICATION_FAILURE":
      return Object.assign({}, state, {
        error: action.payload,
        loading: false,
        sent: false
      });
    default:
      return state;
  }
}
