import React from 'react';

type Props = {
  error: boolean;
};
export default ({ error = false }: Props) => {
  if (error) {
    // When the loader has errored
    return <div>Sorry, there was a problem loading the page.</div>;
  }
  // else if (props.pastDelay) {
  //   // When the loader has taken longer than the delay
  //   return <div>Loading...</div>
  // }
  return null;
};
