import React from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";
import Helmet from "react-helmet";

// Custom
import * as Actions from "../actions/user";
import AdminRoutes from "./AdminRoutes";
import AuthRoutes from "./AuthRoutes";
import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";
import NoMatch from "../containers/404";
import Sidebar from "../containers/Sidebar";
import PublicSidebar from "../containers/PublicSidebar";
import ErrorFallBack from "../components/ErrorFallBack";

const title = "Health Hunter Africa";

const PrivateRoute = ({ component: Component, title, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <React.Fragment>
          <Helmet title={title} />
          <Sidebar
            children={
              <React.Suspense fallback={<ErrorFallBack />}>
                <Component {...props} {...rest} />
              </React.Suspense>
            }
          />
        </React.Fragment>
      ) : (
        <Redirect to={{ pathname: "/login" }} />
      )
    }
  />
);
const PublicRoute = ({ component: Component, title, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <React.Fragment>
        <Helmet title={title} />
        <PublicSidebar
          children={
            <React.Suspense fallback={<ErrorFallBack />}>
              <Component {...props} {...rest} />
            </React.Suspense>
          }
        />
      </React.Fragment>
    )}
  />
);

class AppNavigation extends React.Component {
  render() {
    const { isLoggedIn, resetStore } = this.props;
    const token = sessionStorage.getItem("AuthToken");
    if (isLoggedIn && !token) {
      resetStore();
    }
    return (
      <Router>
        <React.Fragment>
          <Helmet title={title} titleTemplate={`${title} | %s`} />
          <Switch>
            {PublicRoutes.map((route, i) => {
              return <PublicRoute key={i} {...route} />;
            })}
            {PrivateRoutes.map((route, i) => {
              return (
                <PrivateRoute
                  key={i}
                  {...route}
                  isLoggedIn={isLoggedIn && token}
                />
              );
            })}
            {AuthRoutes.map((route, i) => {
              return <Route key={i} {...route} />;
            })}
            {AdminRoutes.map((route, i) => {
              return (
                <PrivateRoute
                  key={i}
                  {...route}
                  isLoggedIn={isLoggedIn && token}
                />
              );
            })}
            <Route component={NoMatch} />
          </Switch>
        </React.Fragment>
      </Router>
    );
  }
}
const mapStateToProps = state => ({
  isLoggedIn: state.user.authenticated
});
const mapDispatchToProps = dispatch => ({
  resetStore: () => dispatch(Actions.logoutUserRequest())
});
export default connect(mapStateToProps, mapDispatchToProps)(AppNavigation);
