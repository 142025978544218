import styled from 'styled-components';

export default styled.p`
  font-size: ${props =>
    props.fontSize
      ? props.fontSize
      : props.bigger
      ? 24
      : props.big
      ? 18
      : 14}px;
  text-align: ${props => props.align || 'left'};
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor || 'transparent'};
  margin: ${props => props.margin || 0};
  font-weight: 200;
  font-style: ${props => (props.big || props.bigger ? 'italic' : 'normal')};
  &:hover {
    cursor: ${props => (props.link ? 'pointer' : 'default')};
    text-decoration: ${props => (props.link ? 'underline' : 'none')};
  }
`;
