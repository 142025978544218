import * as React from 'react';
import { withRouter } from 'react-router-dom';
// import { RouteComponentProps } from 'react-router';

import {
  Tooltip,
  StyledLink,
  StyledExpand,
  LetterAvatar,
  NavItemWrapper,
  StyledCollapse,
  StyledSubMenuLink
} from './SideBarLinkElements';
import { SP, P } from '../Text';
import { Colors } from '../../themes';

// helper methods
import { getArrayFromObject } from '../../utils/helpers';

const { textGray, white } = Colors;

// export type SideBarLinkProps = RouteComponentProps & {
//   to: string;
//   bg?: string;
//   open: boolean;
//   title: string;
//   color?: string;
//   pathname?: string;
//   hasSubMenu?: boolean;
//   icon?: React.ReactNode;
//   location?: { pathname?: string };
//   item?: { to: string; title: string };
//   map?: (item: object) => React.ReactNode;
//   subMenu?: Array<{ to: string; title: string }>;
// };

class SideBarLink extends React.Component {
  static defaultProps = {
    backgroundColor: 'transparent',
    color: white
  };

  state = {
    expanded: false
  };

  componentDidMount() {
    const { subMenu, location } = this.props;

    if (location) {
      if (
        getArrayFromObject(subMenu).some(path => path.to === location.pathname)
      ) {
        // we are in the this route so we open submenu.
        this.setState({ expanded: true });
      }
    }
  }

  getLetters(title: string) {
    let matches = title.match(/\b(\w)/g);
    if (matches) {
      if (matches.length < 3) {
        return matches.join('');
      }
      matches = matches.slice(1, 3);
      return matches.join('');
    }
    return '';
  }

  toggleExpanded = (event) => {
    event.preventDefault();
    const { subMenu } = this.props;
    if (subMenu) {
      this.setState({
        expanded: !this.state.expanded
      });
    }
  };

  isChildActive() {
    const { subMenu, location } = this.props;
    if (location) {
      return getArrayFromObject(subMenu).some(
        path => path.to === location.pathname
      );
    }
    return undefined;
  }

  renderIcon = (icon) => {
    if (React.isValidElement(icon)) {
      return icon;
    }
    return null;
  };

  renderSubMenu = (
    subMenu,
    { ...props }
  ) => {
    return subMenu.map(item => (
      <StyledSubMenuLink
        to={item.to}
        key={item.title}
        activeClassName="active-submenu-link"
        className={`${props.open ? 'nav-link' : 'nav-link-text-hidden'}`}
      >
        <NavItemWrapper hasSubMenu open={props.open}>
          {props.open ? (
            <SP color={textGray}>{item.title}</SP>
          ) : (
            <LetterAvatar open={props.open}>
              <P className="abbreviated-letters">
                {this.getLetters(item.title)}
              </P>
              <Tooltip>
                <P>{item.title}</P>
              </Tooltip>
            </LetterAvatar>
          )}
        </NavItemWrapper>
      </StyledSubMenuLink>
    ));
  };

  render() {
    const { icon, to, title, open, color, subMenu, bg } = this.props;
    const { expanded } = this.state;
    const hasSubMenu = !!subMenu;
    return (
      <React.Fragment>
        <StyledLink
          to={to}
          hasSubMenu={hasSubMenu}
          open={expanded}
          onClick={hasSubMenu ? this.toggleExpanded : () => null}
          activeClassName="active-nav-link"
          childLinkActive={this.isChildActive()}
          className={`${open ? 'nav-link' : 'nav-link-text-hidden'}`}
        >
          <NavItemWrapper open={open} bg={bg}>
            {this.renderIcon(icon)}
            <SP margin="0 0 0 20px" color={color} weight="bold">
              {title}
            </SP>
            {subMenu && (
              <StyledExpand size={20} open={open} expanded={expanded} />
            )}
            {!open && (
              <Tooltip>
                <P>{title}</P>
              </Tooltip>
            )}
          </NavItemWrapper>
        </StyledLink>
        <StyledCollapse
          active={this.isChildActive()}
          open={expanded}
          sidebarOpen={open}
        >
          {subMenu &&
            typeof subMenu !== undefined &&
            this.renderSubMenu(subMenu, { open, color })}
        </StyledCollapse>
      </React.Fragment>
    );
  }
}

export default withRouter(SideBarLink);
