import "./App.css";
import React, { useEffect } from "react";
import config from "./config/Firebase";
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";

/** services */
import firebase from "./services/Firebase";

/** App context */
import ContextProvider from "./components/ContextProvider";

/** Presentational */
import AppNavigation from "./navigation/AppNavigation";

const bugsnagClient = bugsnag("ba97b9dc96dc4bf27ce7ff8589c4db52");
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin("react");
const App = () => {
  firebase.initializeApp(config);
  
  useEffect(() => {
    const currentHost = window.location.hostname;
    if (currentHost === 'healthhunter-frontend-6047bf6cd3b1.herokuapp.com') {
      window.location.href = 'https://www.healthhunterafrica.com' + window.location.pathname;
    }
  }, []);

  return (
    <ErrorBoundary>
      <ContextProvider>
        <AppNavigation />
      </ContextProvider>
    </ErrorBoundary>
  );
};

export default App;
