import { applyMiddleware, createStore, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import logger from "redux-logger";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
import thunk from "redux-thunk";
import rootReducer from "../reducers";

const persistConfig = {
  key: "root",
  storage
};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore() {
  let store =
    process.env.NODE_ENV === "development"
      ? createStore(
          persistedReducer,
          composeEnhancers(applyMiddleware(thunk, logger))
        )
      : createStore(persistedReducer, compose(applyMiddleware(thunk)));
  let persistor = persistStore(store);
  return { store, persistor };
}
