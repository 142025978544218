export default function blogs(state = {}, action) {
  switch (action.type) {
    case "CREATE_BLOG_REQUEST":
    case "VIEW_BLOGS_REQUEST":
    case "GET_BLOG_REQUEST":
    case "EDIT_BLOG_REQUEST":
    case "DELETE_BLOG_REQUEST":
    case "BLOG_SEARCH_REQUEST":
      return {
        ...state,
        message: null,
        error: null,
        loading: true
      };
    case "CREATE_BLOG_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        message: `Thank you for the great article, it will be approved shortly.`
      };
    case "CREATE_BLOG_FAILURE":
      return {
        ...state,
        loading: false,
        message: null,
        error: action.err
      };
    case "VIEW_BLOGS_SUCCESS":
      return {
        ...state,
        loading: false,
        all: [...action.blogs]
      };
    case "VIEW_BLOGS_FAILURE":
      return {
        ...state,
        message: null,
        error: action.error,
        loading: false
      };
    case "VIEW_BLOG":
      return {
        ...state,
        current: action.currentBlog,
        loading: false
      };
    case "GET_BLOG_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        current: action.blog
      };
    case "GET_BLOG_FAILURE":
      return {
        ...state,
        loading: false,
        message: null,
        error: action.err
      };
    case "EDIT_BLOG_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        message: `Your blog has been successfully updated`
      };
    case "EDIT_BLOG_FAILURE":
      return {
        ...state,
        loading: false,
        message: null,
        error: action.err
      };
    case "DELETE_BLOG_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        message: `The blog has been successfully deleted`
      };
    case "DELETE_BLOG_FAILURE":
      return {
        ...state,
        loading: false,
        message: null,
        error: action.err
      };
    case "BLOG_SEARCH_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        results: action.blogs
      };
    case "BLOG_SEARCH_FAILURE":
      return {
        ...state,
        loading: false,
        err: action.err
      };
    case "RESET_STORE":
      return {};
    default:
      return state;
  }
}
