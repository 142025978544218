export default function emails(state = {}, action) {
  switch (action.type) {
    case "SEND_JOB_SEEKER_EMAIL_REQUEST":
      return {
        ...state,
        message: null,
        error: null,
        loading: true
      };
    case "SEND_JOB_SEEKER_EMAIL_SUCCESS":
      return {
        ...state,
        message: action.email,
        error: null,
        loading: false
      };
    case "SEND_JOB_SEEKER_EMAIL_FAILURE":
      return {
        ...state,
        message: null,
        error: action.err,
        loading: false
      };
    default:
      return state;
  }
}
