export default function user(state = {}, action) {
  switch (action.type) {
    case "CREATE_USER_REQUEST":
      return {
        ...state,
        message: null,
        error: null,
        loading: true,
        subscription: false
      };
    case "CREATE_USER_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        message: `Thank you for signing up. Your account will be approved shortly via email`,
        subscription: action.user.role === "user"
      };
    case "LOAD_SIGNUP":
      return {
        ...state,
        message: null,
        error: null,
        loading: false,
        subscription: false
      };
    case "CREATE_USER_FAILURE":
      return {
        ...state,
        loading: false,
        message: null,
        error: action.err
      };
    case "LOGIN_USER_REQUEST":
      return {
        ...state,
        currentUser: null,
        message: null,
        error: null,
        loading: true
      };
    case "LOGIN_USER_SUCCESS":
      return {
        ...state,
        currentUser: action.user,
        message: action.message,
        error: null,
        loading: false,
        authenticated: true
      };
    case "LOGIN_USER_FAILURE":
      return {
        ...state,
        error: action.error,
        message: "Not logged in",
        loading: false
      };
    case "GET_USER_REQUEST":
    case "GET_USERS_REQUEST":
      return {
        ...state,
        loading: true
      };
    case "GET_USER_SUCCESS":
      return {
        ...state,
        user: action.user,
        message: action.message,
        error: null,
        loading: false,
        authenticated: true
      };
    case "GET_USER_FAILURE":
      return {
        ...state,
        message: action.message,
        error: action.err,
        loading: false,
        authenticated: true
      };

    case "GET_USERS_SUCCESS":
      return {
        ...state,
        all: action.users,
        message: action.message,
        error: null,
        loading: false,
        authenticated: true
      };
    case "GET_USERS_FAILURE":
      return {
        ...state,
        message: action.message,
        error: action.err,
        loading: false,
        authenticated: true
      };
    case "EDIT_USER_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        message: null
      };
    case "VERIFY_PASSWORD_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        message: null,
        verified: undefined
      };
    case "VERIFY_PASSWORD_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        message: null,
        verified: true
      };
    case "VERIFY_PASSWORD_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
        message: null,
        verified: false
      };
    case "RESET_PASSWORD_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        message: null,
        reset: undefined
      };
    case "RESET_PASSWORD_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        message: null,
        reset: true
      };
    case "RESET_PASSWORD_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.message,
        message: null,
        reset: false
      };
    case "EDIT_USER_SUCCESS":
      return {
        ...state,
        currentUser: action.user,
        loading: false,
        error: null,
        message: null
      };
    case "EDIT_USER_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.err,
        message: action.err.message
      };
    case "RESET_STORE":
      return {};
    default:
      return state;
  }
}
